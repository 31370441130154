import React from 'react';
import PropTypes from 'prop-types';
import usePerformance from '../../../../../hooks/myEvolution/myPerformance/usePerformance';
import Separator from '../../../../shared/Separator';
import '../../../../../static/styles/mievolucion/objectives2022/advance-comments-description.less';
import InfoMessage from '../../../../shared/InfoMessage';

const bossNoComment = 'El proceso de Pulso finalizó, por lo cual ya no podrás realizar modificaciones. Invita a tu colaborador a Autoevaluarse en la sección Evaluación de Desempeño.';
const AdvanceCommentsDescription = ({
  comments, bossParams, quarter, isLastPulse = false
}) => {
  const { data: performance = {} } = usePerformance();
  const { quarterCurrent, status } = performance;
  const bossHasCommented = !!comments?.commentBoss?.trim();
  const colaboradorHasCommented = !!comments?.commentColaborate?.trim();

  const getEmptyCommentMessage = () => {
    if (performance.status === 'CLOSED') {
      if (isLastPulse) {
        return bossParams ? 'El proceso de Pulso finalizó, por lo cual ya no podrás realizar modificaciones. Invita a tu colaborador a Autoevaluarse en la sección Evaluación de Desempeño.'
          : 'El proceso de Pulso finalizó, por lo cual ya no podrás realizar modificaciones. Te invitamos a Autoevaluarte en la sección Evaluación de Desempeño.';
      }
      return 'No se realizaron comentarios';
    }
    return 'No se han realizado comentarios';
  };
  if (!bossHasCommented && !colaboradorHasCommented) {
    return <InfoMessage {...{
      suave: true,
      color: 'warning',
      message: getEmptyCommentMessage()
    }}/>;
  }

  const emptyBossMessage = () => {
    if (quarter !== quarterCurrent || status.current === 'CLOSED') {
      if (isLastPulse) {
        return bossParams ? bossNoComment : 'El pulso finalizó por lo que tu jefe no alcanzo a cerrar el pulso e ingresar comentarios en este periodo.';
      }
      return bossParams ? 'No hiciste comentarios' : 'Tu jefe no hizo ningún comentario';
    }

    return bossParams ? 'No has hecho ningún comentario' : 'Tu jefe no ha hecho ningún comentario';
  };
  const emptyColaborateMessage = () => {
    if (quarter !== quarterCurrent || status.current === 'CLOSED') {
      return bossParams ? `${bossParams.selectedUser.name} no realizó ningún comentario` : 'No hiciste comentarios';
    }
    return bossParams ? `${bossParams.selectedUser.name} no ha hecho comentarios` : 'No has hecho ningún comentario';
  };
  return (
    <div>
      <div className='advance-comments-description'>
        <div className='my-advance-comment'>
          <label>
            {bossParams ? 'Tu comentario' : 'Comentario de tu jefe' }
          </label>
          { bossHasCommented
            ? <p>{comments.commentBoss}</p>
            : <p> {emptyBossMessage()}</p>
          }

        </div>
      </div>
      <Separator />
      <div className='advance-comments-description'>
        <div className='my-advance-comment'>
          <label>
            {bossParams ? `Comentario de ${bossParams.selectedUser.name}` : 'Mi comentario'}
          </label>
          { colaboradorHasCommented
            ? <p>{comments.commentColaborate}</p>
            : <p> {emptyColaborateMessage()}</p>
          }
        </div>
      </div>
    </div>
  );
};

AdvanceCommentsDescription.typeProps = {
  comments: PropTypes.object,
  bossParams: PropTypes.object,
  quarter: PropTypes.string
};

AdvanceCommentsDescription.defaultProps = {
  comments: null,
  bossParams: null,
  quarter: undefined
};

export default AdvanceCommentsDescription;

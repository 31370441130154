/* eslint-disable no-new-object */
/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { completePath } from '../strings';
import { isProduction } from '../commons';
import { DESEMPENO_URL, EDD_URL, FEEDBACK_URL } from './myPerformance';

export const getMiDesempeñoLink = ({
  MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO,
  MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD,
  MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK,
  isEDDEnabled
}) => {
  if (MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD && isEDDEnabled) return EDD_URL;
  if (MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO) return DESEMPENO_URL;
  if (MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK) return FEEDBACK_URL;
  return '/mi-evolucion/mi-desempeño';
};

export const dataMyManagement = (permissions, isEDDEnabled) => {
  const {
    MI_EVOLUCION_MI_GESTION_NUEVAS_COMPETENCIAS,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO,
    MI_EVOLUCION_MI_GESTION_RETRO,
    MI_EVOLUCION_MI_GESTION_CALIBRACION,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK
  } = permissions;

  return [
    {
      title: 'Mis Competencias 4C',
      image: `${completePath('web/mi-evolucion/servicios/inicio/nuevas-competencias-card.jpg')}`,
      link: '/mi-evolucion/mis-competencias-4C',
      visible: MI_EVOLUCION_MI_GESTION_NUEVAS_COMPETENCIAS
    },
    {
      title: 'Objetivos',
      image: `${completePath('web/mi-evolucion/servicios/inicio/objetivos-card.jpg')}`,
      link: '/mi-evolucion/objetivos',
      visible: false
    },
    {
      title: 'Retroalimentación',
      image: `${completePath('web/mi-evolucion/servicios/inicio/evaluacion-de-desempeno-RETRO-card.jpg')}`,
      link: '/mi-evolucion/retroalimentación',
      visible: MI_EVOLUCION_MI_GESTION_RETRO
    },
    {
      title: 'Calibración',
      image: completePath('web/mi-evolucion/servicios/inicio/calibracion-card.jpg'),
      link: '/mi-evolucion/calibracion',
      visible: MI_EVOLUCION_MI_GESTION_CALIBRACION,
      onlyDesktop: true
    },
    {
      title: 'Mi Desempeño',
      image: completePath('web/mi-evolucion/servicios/inicio/midesempeno-card.jpg'),
      link: getMiDesempeñoLink({
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO,
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD,
        MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK,
        isEDDEnabled
      }),
      visible: MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO
    }
  ];
};
export const dataMyLearning = (permissions) => {
  const { data } = useSelector((state) => state.userReducer);
  const HO = 'HO';
  const CLIKMA_TITLE = data.HOOPP === HO ? 'Mi aprendizaje online learning' : 'Academia de operaciones demo';
  const CLIKMA_IMAGE = data.HOOPP === HO ? 'mi-aprendizaje-online-learning.png' : 'academia-operaciones-demo.png';
  const CLIKMA_URL = isProduction() ? 'https://walmart.clikma.com' : 'https://walmart.demo.clikma.com';
  const {
    MI_EVOLUCION_MI_APRENDIZAJE_CHARLAS_Y_FORMACION,
    MI_EVOLUCION_MI_APRENDIZAJE_LINKEDIN_LEARNING
  } = permissions;

  return [
    {
      title: 'Mi Aprendizaje',
      image: `${completePath('web/mi-evolucion/servicios/inicio/charlas-y-formacion-card.jpg')}`,
      link: 'https://one.walmart.com/content/mi-lugar/es_cl.html',
      visible: MI_EVOLUCION_MI_APRENDIZAJE_CHARLAS_Y_FORMACION,
      target: '_blank'
    },
    {
      title: 'Harvard Spark',
      image: `${completePath('web/mi-evolucion/servicios/inicio/harvard-spark.png')}`,
      link: 'https://walmart.myhbp.org/lde/welcome',
      visible: MI_EVOLUCION_MI_APRENDIZAJE_LINKEDIN_LEARNING,
      target: '_blank'
    },
    {
      title: 'Harvard Manage Mentor',
      image: `${completePath('web/mi-evolucion/servicios/inicio/harvard-spark-manager.png')}`,
      link: 'https://walmart.myhbp.org/hmm12/home.html',
      visible: MI_EVOLUCION_MI_APRENDIZAJE_LINKEDIN_LEARNING,
      target: '_blank'
    },
    {
      title: CLIKMA_TITLE,
      image: `${completePath(`web/academia-de-operaciones/servicios/inicio/${CLIKMA_IMAGE}`)}`,
      link: `${CLIKMA_URL}/token/${data.clikmaToken}`,
      visible: true,
      target: '_blank'
    }
  ];
};
const dataMyWay = (permissions) => {
  const {
    MI_EVOLUCION_MI_CAMINO_POSTULACIONES_INTERNAS,
    MI_EVOLUCION_MI_CAMINO_EVOLUCIONA_TEST,
    INTERESTS_AND_SKILLS,
    MI_EVOLUCION_MI_CAMINO_MI_DESARROLLO
  } = permissions;

  return [
    {
      title: 'Postulaciones Internas',
      image: `${completePath('web/mi-evolucion/servicios/inicio/postulaciones-internas-card.jpg')}`,
      link: '/mi-evolucion/postulaciones-internas',
      visible: MI_EVOLUCION_MI_CAMINO_POSTULACIONES_INTERNAS
    },
    {
      title: 'EvolucionaTEST',
      image: `${completePath('web/mi-evolucion/servicios/inicio/evolucionatest-card.jpg')}`,
      link: '/mi-evolucion/evolucionaTEST',
      visible: !isProduction() && MI_EVOLUCION_MI_CAMINO_EVOLUCIONA_TEST
    },
    {
      title: 'Intereses y habilidades',
      image: `${completePath('web/mi-evolucion/servicios/inicio/intereses-y-habilidades-card.jpg')}`,
      link: '/mi-evolucion/intereses-y-habilidades',
      visible: INTERESTS_AND_SKILLS
    },
    {
      title: 'Mi desarrollo',
      image: `${completePath('web/mi-evolucion/servicios/inicio/intereses-y-habilidades-card.jpg')}`,
      link: '/mi-evolucion/mi-desarrollo',
      visible: MI_EVOLUCION_MI_CAMINO_MI_DESARROLLO
    }
  ];
};

export const getHomeList = ({ permissions, isEDDEnabled }) => {
  const {
    MI_EVOLUCION = false,
    MI_EVOLUCION_MI_APRENDIZAJE = false,
    MI_EVOLUCION_MI_APRENDIZAJE_CHARLAS_Y_FORMACION = false,
    MI_EVOLUCION_MI_APRENDIZAJE_LINKEDIN_LEARNING = false,
    MI_EVOLUCION_MI_CAMINO = false,
    MI_EVOLUCION_MI_CAMINO_FEEDBACKS = false,
    MI_EVOLUCION_MI_CAMINO_POSTULACIONES_INTERNAS = false,
    MI_EVOLUCION_MI_CAMINO_EVOLUCIONA_TEST = false,
    MI_EVOLUCION_MI_CAMINO_MI_DESARROLLO = false,
    MI_EVOLUCION_MI_GESTION = false,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO = false,
    MI_EVOLUCION_MI_GESTION_CALIBRACION = false,
    MI_EVOLUCION_MI_GESTION_RETRO = false,
    INTERESTS_AND_SKILLS = false,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK
  } = permissions || {};

  const permissionsMyManagement = {
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO,
    MI_EVOLUCION_MI_GESTION_RETRO,
    MI_EVOLUCION_MI_GESTION_CALIBRACION,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_DESEMPEÑO,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_EDD,
    MI_EVOLUCION_MI_GESTION_MI_DESEMPEÑO_FEEDBACK
  };
  const permissionsMyLearning = {
    MI_EVOLUCION_MI_APRENDIZAJE_CHARLAS_Y_FORMACION,
    MI_EVOLUCION_MI_APRENDIZAJE_LINKEDIN_LEARNING
  };
  const permissionsMyWay = {
    MI_EVOLUCION_MI_CAMINO_FEEDBACKS,
    MI_EVOLUCION_MI_CAMINO_POSTULACIONES_INTERNAS,
    MI_EVOLUCION_MI_CAMINO_EVOLUCIONA_TEST,
    INTERESTS_AND_SKILLS,
    MI_EVOLUCION_MI_CAMINO_MI_DESARROLLO
  };

  if (MI_EVOLUCION) {
    const listHome = new Object();

    if (MI_EVOLUCION_MI_GESTION) {
      listHome['Mi Gestión'] = dataMyManagement(permissionsMyManagement, isEDDEnabled);
    }
    if (MI_EVOLUCION_MI_APRENDIZAJE) {
      listHome['Mi Aprendizaje'] = dataMyLearning(
        permissionsMyLearning
      );
    }

    if (MI_EVOLUCION_MI_CAMINO) {
      listHome['Mi Camino'] = dataMyWay(permissionsMyWay);
    }

    return listHome;
  }
  return [];
};

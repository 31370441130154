import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { getEvaluationsReport, cleanEvaluationsReport } from '../../../redux/actions/evaluationsReport';
import { getCalibrationsReport, cleanCalibrationsReport } from '../../../redux/actions/calibrationsReport';
import ModalMessage from '../../shared/ModalMessage';
import Report from './Report';
import { completePath } from '../../../helpers/strings';
// Components

const urlPdf = completePath('downloads/mievolucion/calibracion/modelo-calibraciones-masivas.xlsx');

const Reports = () => {
  const dispatch = useDispatch();

  const { emailOffice } = useSelector((state) => state.userReducer.data);

  const {
    loading: loadingEvaluationsReport,
    data: evaluationsReportData,
    error: evaluationsReportError
  } = useSelector((state) => state.evaluationsReportReducer);

  const {
    loadingCalibrationReport,
    calibrationReport,
    errorCalibrationReport
  } = useSelector((state) => state.calibrationsReportReducer);

  const [modalMessage, setModalMessage] = useState({ visible: false, type: 'error' });

  const openEmailAdvice = () => {
    setModalMessage({
      visible: true,
      type: 'info',
      title: 'Se está generando el reporte de evaluaciones.',
      message: `El reporte será enviado a tu correo electronico ${emailOffice.toLowerCase()} cuando se encuentre listo. Puede que tarde unos minutos.`
    });
  };

  const openModalMessageError = () => {
    setModalMessage({
      ...modalMessage,
      visible: true,
      title: 'Ha ocurrido un error repentino'
    });
  };

  const reportsList = [
    {
      title: 'REPORTE CALIBRACIONES',
      icon: 'icon-ml-excel',
      onClick: () => {
        dispatch(getCalibrationsReport());
      },
      loading: loadingCalibrationReport,
      data: calibrationReport,
      error: errorCalibrationReport,
      clean: cleanCalibrationsReport,
      visible: true,
      willGetFile: false
    },
    {
      title: 'REPORTE EVALUACIONES',
      icon: 'icon-ml-excel',
      onClick: () => {
        dispatch(getEvaluationsReport());
      },
      loading: loadingEvaluationsReport,
      data: evaluationsReportData,
      error: evaluationsReportError,
      clean: cleanEvaluationsReport,
      visible: true,
      willGetFile: false
    },
    {
      title: 'MODELO DE CARGA MASIVA',
      icon: 'icon-ml-excel',
      onClick: () => {
        window.open(urlPdf);
      },
      loading: false,
      data: evaluationsReportData,
      error: evaluationsReportError,
      clean: cleanEvaluationsReport,
      visible: true
    }
    // {
    //   title: 'COMENTARIOS CALIBRACIONES',
    //   icon: 'icon-ml-newtab',
    //   onClick: () => {
    //     const url = 'https://my.wal-mart.com/:x:/r/personal/m0m05y5_cl_wal-mart_com/_layouts/15/Doc.aspx?sourcedoc=%7B312FDAA9-6042-441C-8D67-B3C2F4E26961%7D&file=Registro%20Comentarios%20Calibraciones%202022.xlsx&action=default&mobileredirect=true';
    //     window.open(url);
    //   },
    //   loading: false,
    //   data: evaluationsReportData,
    //   error: evaluationsReportError,
    //   clean: cleanEvaluationsReport,
    //   visible: true
    // },
    // {
    //   title: 'COMENTARIOS G. PERSONAS',
    //   icon: 'icon-ml-newtab',
    //   onClick: () => {
    //     const url = 'https://my.wal-mart.com/:x:/r/personal/m0m05y5_cl_wal-mart_com/_layouts/15/Doc.aspx?sourcedoc=%7B11AF3740-13C6-4521-98AD-983EC0033742%7D&file=Registro%20Comentarios%20Calibraciones%202022%20-%20G.%20Personas.xlsx&action=default&mobileredirect=true';
    //     window.open(url);
    //   },
    //   loading: false,
    //   data: evaluationsReportData,
    //   error: evaluationsReportError,
    //   clean: cleanEvaluationsReport,
    //   visible: true
    // }
  ];

  const onEvaluationSuccessfullyCalled = ({ willGetFile }) => {
    if (!willGetFile) openEmailAdvice();
  };

  useEffect(() => {
    if (!modalMessage.visible) {
      if (evaluationsReportError) {
        dispatch(cleanEvaluationsReport());
      } else if (errorCalibrationReport) {
        dispatch(cleanCalibrationsReport());
      }
    }
  }, [modalMessage]);

  return (
    <div className='container-reports'>
      {
        map(reportsList, (report, key) => (
          <Report
            key={key}
            {...report}
            onSuccess={onEvaluationSuccessfullyCalled}
            openModalMessageError={openModalMessageError}
          />
        ))
      }
      <ModalMessage {...{
        ...modalMessage,
        closeModal: () => setModalMessage({ ...modalMessage, visible: false })
      }}/>

    </div>
  );
};
export default Reports;

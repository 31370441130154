import React from 'react';
import { Carousel } from 'antd-v4';
import Message from '../../../../../../shared/InfoMessage';
import { smallDevice } from '../../../../../../../helpers/deviceVariables';
import { useWindowWidthSize } from '../../../../../../../helpers/browser';

const SkillExpand = ({
  _id,
  userName,
  name,
  status,
  categories,
  behaviors,
  categorySelected,
  onSkillChange,
  isAutoevaluation,
  canEdit
}) => {
  const width = useWindowWidthSize();
  const isMobile = width <= smallDevice;
  return (
    <div className='px-5 py-4 competencies-expand w-100'>
      <p className='mb-3'>
        <b>Conductas esperadas del rol:</b>
      </p>
      {!isMobile ? <div className='d-flex mb-4 descriptions-container'>
        {behaviors.map((desc, i) => (
          <div className='pr-4 bd-between' key={i}>
            <p className='mb-2 color-primary'>
              <b>{desc.title}</b>
            </p>
            <p>{desc.description}</p>
          </div>
        ))}
      </div>
        : <Carousel className='descriptions-carousel mb-2'>
          {behaviors.map((desc, i) => (
            <div className='p-4' key={i}>
              <p className='mb-2 color-primary'>
                <b>{desc.title}</b>
              </p>
              <p>{desc.description}</p>
            </div>
          ))}
        </Carousel> }
      <p className='mb-4 ml-4 with-dot red'>
        {!isAutoevaluation ? (
          <b>
          Evalúa el desempeño de {userName} en la competencia {name}:
          </b>
        ) : (
          <b>Evalúa tu desempeño en la competencia {name}:</b>
        )}
      </p>
      {!isAutoevaluation && (
        <Message classes={`mb-3 ${ !status ? 'info-message-warning' : ''}`} suave>
          {status ? (
            <p>
          El resultado de su autoevaluación es <b>{status}</b>
            </p>
          ) : (
            <p>{userName} aún no ha realizado su autoevaluación.</p>
          )}
        </Message>
      )}
      <div
        className='d-flex rounded mb-4 ml-border-primary options-container'
      >
        {categories.map((category) => (
          <div
            key={category._id}
            className='bd-between ml-flex-1 evaluation-option d-flex flex-column'
          >
            <div className='px-5 py-1 text-center rounded-top option-header' onClick={() => onSkillChange(_id, category._id)}>
              <input
                type='radio'
                value={category._id}
                checked={categorySelected?._id === category._id}
                onChange={(e) => onSkillChange(_id, e.target.value)}
                disabled={!canEdit}
              />
              <p className='color-primary'>
                <b>{category.name}</b>
              </p>
            </div>
            <div className='p-3 text-center rounded-bottom option-body h-100'>
              <p>{category.skillDescription}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SkillExpand;

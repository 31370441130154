/* eslint-disable no-nested-ternary */
import React from 'react';
import ObjetivesExpand from './steps/ObjetiveExpand';
import SkillExpand from './steps/SkillExpand';
import {
  ResCompetenciesExpand,
  ResObjetivesExpand
} from './steps/ResumeExpand';
import { PENDING } from '../../../../../../helpers/myEvolution/evaluations';
import {
  fullCapitalizeFormat
} from '../../../../../../helpers/strings';

const createMockedData = (
  evaluation,
  autoevaluation,
  skills,
  categories,
  onSkillChange,
  objectives,
  onObjectiveChange,
  finalResults,
  isAutoevaluation,
  canEdit,
  setStep,
  isMobile
) => {
  const { evaluatorCanEditObjectives } = evaluation;
  const canEvaluatorEdit = !isAutoevaluation && evaluatorCanEditObjectives;

  const canEditObjetives = isAutoevaluation || canEvaluatorEdit;
  const skillsExpRender = (row) => <SkillExpand {...row}/>;

  const objectivesExpRender = (row) => <ObjetivesExpand
    {...row}
    canEditObjetives={canEditObjetives} />;

  const resumeCompExpRender = (row) => <ResCompetenciesExpand {...row} />;

  const resumeObjExpRender = (row) => <ResObjetivesExpand {...row} />;

  const skillsCompletionCount = skills.filter(
    (skill) => skill.categorySelected?.name
  ).length;
  const objectivesCompletionCount = objectives.filter(
    (obj) => obj.categorySelected?.name
  ).length;

  const competenciescolors = ['#EDA300', '#CB198A', '#00BAA7', '#7552C1'];

  const evaluatedName = fullCapitalizeFormat(evaluation?.evaluated?.name || '');
  return {
    stepLabels: [
      {
        value: '1',
        numberStatus: 1,
        label: 'Competencias'
      },
      {
        value: '2',
        numberStatus: 2,
        label: 'Objetivos'
      },
      {
        value: '3',
        numberStatus: 3,
        label: 'Resumen de evaluación'
      }
    ],
    stepsData: {
      1: {
        description: `Estas son las competencias que debes ${
          isAutoevaluation
            ? 'autoevaluarte'
            : `evaluar de ${evaluatedName}`
        }. `,
        completePercent: `${skillsCompletionCount} de ${skills.length}`,
        expandableRender: skillsExpRender,
        skillsResult:
          skillsCompletionCount === skills.length
            ? finalResults.skills?.name || 'Completado'
            : 'Pendiente',
        tableData: {
          columns: [
            {
              title: 'Competencias',
              dataIndex: 'name'
            },
            {
              title: 'Autoevaluación',
              dataIndex: 'status',
              hidden: isAutoevaluation || isMobile,
              render: (text) => text || <span className='with-dot red'>Sin evaluación</span>
            },
            {
              title: isAutoevaluation ? 'Autoevaluación' : 'Evaluación',
              dataIndex: 'evaluationStatus',
              render: (text) => text || <span className='with-dot red'>Sin evaluación</span>
            },
            {
              title: '',
              dataIndex: 'actions',
              align: 'right',
              hidden: isMobile,
              render: () => (
                <a className='table-link'>
                  Completar <i className='fas fa-pencil-alt'></i>
                </a>
              )
            }
          ].filter((col) => !col.hidden),
          rowsData: skills.map((skill, i) => ({
            ...skill,
            key: skill._id,
            status:
              autoevaluation?.status !== PENDING && skill.categorySelectedAuto,
            evaluationStatus: skill.categorySelected?.name,
            categories,
            colorBar: competenciescolors[i],
            userName: evaluatedName,
            onSkillChange,
            isAutoevaluation,
            canEdit
          }))
        }
      },
      2: {
        userName: evaluatedName,
        description: `Estos son los objetivos que debes ${
          isAutoevaluation
            ? 'autoevaluarte'
            : `evaluar de ${evaluatedName}`
        }. `,
        completePercent: `${objectivesCompletionCount}/${objectives.length}`,
        expandableRender: objectivesExpRender,
        objectivesResult:
          objectivesCompletionCount === objectives.length
            ? finalResults.objectives?.name || 'Completado'
            : 'Pendiente',
        tableData: {
          columns: [
            {
              title: 'Objetivos',
              dataIndex: 'name',
              width: '30%'
            },
            {
              title: isMobile ? '%' : 'Ponderación',
              dataIndex: 'weight',
              render: (text) => `${text}%`
            },
            {
              title: 'Autoevaluación',
              dataIndex: 'status',
              hidden: isAutoevaluation || isMobile,
              render: (text) => text || <span className='with-dot red'>Sin evaluación</span>
            },
            {
              title: isAutoevaluation ? 'Autoevaluación' : 'Evaluación',
              dataIndex: 'evaluationStatus',
              render: (text) => text || <span className='with-dot red'>Sin evaluación</span>
            },
            {
              title: '',
              dataIndex: 'acciones',
              align: 'right',
              hidden: isMobile,
              render: () => (
                canEditObjetives
              && <a className='table-link'>
                  Completar <i className='fas fa-pencil-alt'></i>
              </a>
              )
            }
          ].filter((col) => !col.hidden),
          rowsData: objectives.map((obj) => ({
            ...obj,
            key: obj._id,
            name: obj.objective?.name,
            status:
              autoevaluation?.status !== PENDING && obj.categorySelectedAuto,
            evaluationStatus: obj.categorySelected?.name,
            categories,
            userName: evaluatedName,
            onObjectiveChange,
            isAutoevaluation,
            canEdit: isAutoevaluation ? canEdit : (canEdit && evaluation.evaluatorCanEditObjectives)

          }))
        }
      },
      3: {
        userName: evaluatedName,
        generalResult: finalResults.evaluation?.name,
        generalDesc: finalResults.evaluation?.description,
        skillsResult: finalResults.skills?.name,
        skillsDesc: finalResults.skills?.finalSkillDescription,
        objectivesResult: finalResults.objectives?.name,
        objectivesDesc: finalResults.objectives?.finalObjetivesDescription,
        compExpandableRender: resumeCompExpRender,
        objExpandableRender: resumeObjExpRender,
        skillsTableData: {
          columns: [
            {
              title: 'Competencias',
              dataIndex: 'name',
              className: 'border-r combined-cell',
              width: '50%',
              render: (text) => (
                <div className='d-flex justify-content-between'>
                  <span>{text}</span>
                  {isMobile && (
                    <a className='table-link pr-3' onClick={() => setStep(1)}>
                      Editar <i className='fas fa-pencil-alt'></i>
                    </a>
                  )}
                </div>
              )
            },
            {
              title: 'Categorías',
              dataIndex: 'categoryName',
              width: '30%',
              render: (text) => <p style={{ color: '#666666' }}>{text}</p>
            },
            {
              title: '',
              dataIndex: 'acciones',
              width: '20%',
              align: 'right',
              hidden: isMobile,
              render: () => (
                <a className='table-link pr-3' onClick={() => setStep(1)}>
                  Editar <i className='fas fa-pencil-alt'></i>
                </a>
              )
            }
          ].filter((col) => !col.hidden),
          rowsData: skills.map((skill) => ({
            ...skill,
            key: skill._id,
            categoryName: skill.categorySelected?.name
          }))
        },
        objTableData: {
          columns: [
            {
              title: 'Objetivos',
              dataIndex: 'name',
              className: 'combined-cell',
              width: '45%',
              render: (text) => (
                <div className='d-flex justify-content-between'>
                  <span>{text}</span>
                  {isMobile && canEditObjetives && (
                    <a className='table-link pr-3' onClick={() => setStep(1)}>
                      Editar <i className='fas fa-pencil-alt'></i>
                    </a>
                  )}
                </div>
              )
            },
            {
              title: isMobile ? '%' : 'Ponderación',
              dataIndex: 'weight',
              className: 'border-r ponderation',
              width: '15%',
              render: (text) => `${isMobile ? 'Ponderación ' : ''}${text}%`
            },
            {
              title: 'Categorías',
              dataIndex: 'categoryName',
              width: '25%',
              render: (text) => <p style={{ color: '#666666' }}>{text}</p>
            },
            {
              title: '',
              dataIndex: 'acciones',
              width: '15%',
              align: 'right',
              hidden: isMobile,
              render: () => (
                isAutoevaluation || canEvaluatorEdit ? <a className='table-link pr-3' onClick={() => setStep(2)}>
                  Editar <i className='fas fa-pencil-alt'></i>
                </a>
                  : <></>
              )
            }
          ].filter((col) => !col.hidden),
          rowsData: objectives.map((obj) => ({
            ...obj,
            key: obj._id,
            name: obj.objective?.name,
            categoryName: obj.categorySelected?.name
          }))
        }
      }
    }
  };
};
export default createMockedData;

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Row, Form
} from 'antd';
import {
  map
} from 'lodash';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { GET_INITIAL_DATA, MY_FEEDBACKS, OTHERS_FEEDBACKS } from '../../../../../../graphql/mievolucion/feedbacks/queries';
import {
  RESPOND_FEEDBACK, DELETE_FEEDBACK,
  SET_NOT_FEEDBACK_REASON
} from '../../../../../../graphql/mievolucion/feedbacks/mutations';
import ItemDetailsCard from './Card';
import InfoMessage from '../../../../../shared/InfoMessage';
import FeedbackNetworkCard from './FeedbackNetwork';
import {
  withoutInformation, hasBehaviors, addBehaviors
} from '../../../../../../helpers/myEvolution/feedback';
import { GET_NOTIFICATIONS } from '../../../../../../graphql/notifications/queries';
import Loading from '../../../../../../containers/loading';
import { GET_SKILLS_BEHAVIORS_4C_BY_UCM } from '../../../../../../graphql/mievolucion/skills/queries';

const context = {
  clientName: 'myEvolution'
};

const refetchQueries = [
  { query: MY_FEEDBACKS, context },
  { query: OTHERS_FEEDBACKS, context },
  { query: GET_NOTIFICATIONS }
];

const SingleItemDetails = (props) => {
  const {
    form: { getFieldDecorator },
    categories,
    data = {},
    type,
    setModalState,
    feedbackToMyTeam
  } = props;
  const [
    getSkillBehaviors,
    {
      data: dataListSkillBehaviors,
      loading: loadingListSkillBehaviors
    }
  ] = useLazyQuery(GET_SKILLS_BEHAVIORS_4C_BY_UCM, {
    context,
    fetchPolicy: 'network-only'
  });

  const { listSkillsBehaviors4CByUCM: listSkillsBehaviors = [] } = dataListSkillBehaviors || {};
  const {
    feedback = {}, from
  } = data || {};
  const { data: initialData } = useQuery(GET_INITIAL_DATA, { context });
  const { listDefaultFeedbackAnswers = [] } = initialData || {};

  const [saveFeedbackCommentMutation] = useMutation(RESPOND_FEEDBACK, { context });
  const [deleteFeedbackMutation] = useMutation(DELETE_FEEDBACK, { context });

  useEffect(() => {
    if (hasBehaviors(data?.feedback?.type)) {
      getSkillBehaviors(
        {
          variables:
          {
            ...(data?.feedback?.toUserUCM
              ? { UCM: data.feedback.toUserUCM } : {})
          }
        }
      );
    }
  }, [data]);

  const [setNotFeedbackReasonMutation,
    { loading: loadingNotFeedbackReason }] = useMutation(SET_NOT_FEEDBACK_REASON, {
    context,
    refetchQueries,
    onCompleted: () => {
      setModalState({
        title: 'Se envió tu respuesta',
        icon: 'icon-me-check success-icon-color',
        visible: true
      });
    },
    onError: () => {
      setModalState({
        title: 'No se pudo enviar tu respuesta',
        message: 'Se ha producido un error al intentar enviar tu respuesta. Por favor inténtalo de nuevo más tarde.',
        icon: 'icon-me-error cancel-icon-color',
        visible: true
      });
    }
  });

  const [dontRespondFeedback, setDontRespondFeedback] = useState(false);
  const {
    answer,
    isFeedbackNetwork,
    date, feedbackType, isGroup, title, subTitle, subject
  } = data;
  const [comments, setComments] = useState(data.comments);
  const [notFeedbackReason, setNotFeedbackReason] = useState({
    _id: null,
    comment: ''
  });

  const [pushingComments, pushComments] = useState(false);
  const [deletingFeedback, pushDeleteFeedback] = useState(false);

  const feedbackToMe = type === 'mi';
  const feedbackToOthers = type === 'otros';
  const isPending = data.status === 'Pending';

  const CONDITION_BUTTON_DELETE_FEEDBACK = isPending && feedbackToMe && feedbackType === 'Requested';

  const CONDITION_BUTTON_SEND_FEEDBACK = isPending && feedbackToOthers && feedbackType === 'Requested';

  const deleteFeedback = async (variables) => {
    try {
      pushDeleteFeedback(true);
      await deleteFeedbackMutation({ variables, refetchQueries });

      setModalState({
        title: 'Tu solicitud de feedback ha sido anulada con éxito',
        icon: 'icon-me-check success-icon-color',
        visible: true
      });

      pushDeleteFeedback(false);
    } catch (_) {
      setModalState({
        title: 'El feedback no se ha podido anular',
        message: 'Se ha producido un error al intentar anular el feedback. Por favor inténtalo de nuevo más tarde.',
        icon: 'icon-me-error cancel-icon-color',
        visible: true
      });

      pushDeleteFeedback(false);
    }
  };

  const { status, statusClasses, info } = (() => {
    switch (data.status) {
    case 'Finished': {
      return {
        status: 'Enviado',
        statusClasses: 'icon-me-check success-icon-color'
      };
    }
    case 'Pending': {
      if (CONDITION_BUTTON_DELETE_FEEDBACK) {
        const pendingModalProps = {
          title: '¿Estás seguro que deseas anular la solicitud de feedback?',
          icon: 'icon-ml-warning pending-icon-color',
          buttons: [
            {
              text: 'Cancelar',
              className: 'ant-btn-default'
            },
            {
              type: 'primary',
              text: 'Anular Solicitud',
              className: 'cancel-bg-color'
            }
          ]
        };

        return {
          status: 'Pendiente',
          statusClasses: 'icon-me-feedback-pending pending-icon-color',
          info: <Row type='flex' className='container-info-message'>
            <InfoMessage
              color='warning'
              classes='mt-3'
              message={`${data.title} aún no responde el feedback solicitado`} />

            {!feedbackToMyTeam && !isFeedbackNetwork
          && <Button
            loading={deletingFeedback}
            type='danger'
            onClick={() => {
              setModalState({
                ...pendingModalProps,
                visible: true,
                closeModal: (value) => {
                  if (value === 1) deleteFeedback({ feedbackID: data._id, fromUser: data.rut });
                  setModalState({ ...pendingModalProps, visible: false });
                }
              });
            }}
          >
              Anular solicitud de feedback
          </Button>
            }
          </Row>
        };
      }

      if (CONDITION_BUTTON_SEND_FEEDBACK) {
        const saveFeedbackComment = async (variables) => {
          try {
            pushComments(true);

            await saveFeedbackCommentMutation({
              variables,
              refetchQueries
            });

            pushComments(false);

            setModalState({
              title: 'Tu feedback se ha enviado con éxito',
              icon: 'icon-me-check success-icon-color',
              visible: true
            });
          } catch (_) {
            pushComments(false);

            setModalState({
              title: 'No se ha podido enviar tu feedback con éxito',
              icon: 'icon-me-error cancel-icon-color',
              visible: true
            });
          }
        };

        return {
          status: 'Pendiente',
          statusClasses: 'icon-me-feedback-pending pending-icon-color',
          info:
          <div className='d-flex justify-content-center align-items-center'>
            <Button
              type='primary'
              htmlType='submit'
              loading={pushingComments || loadingNotFeedbackReason}
              onClick={() => {
                props.form.validateFields((err) => {
                  if (!err) {
                    if (dontRespondFeedback && notFeedbackReason) {
                      setNotFeedbackReasonMutation({
                        variables: {
                          reason: {
                            defaultAnswer: notFeedbackReason.name,
                            comment: notFeedbackReason.comment,
                            feedbackID: data._id
                          }
                        }
                      });
                    } else {
                      const variables = {
                        responses: map(comments, ({ _id, content, performanceCategoryID }) => {
                          const vars = {
                            feedbackCommentID: _id,
                            content
                          };
                          if (isFeedbackNetwork) {
                            vars.performanceCategoryID = performanceCategoryID
                          === withoutInformation.value ? null : performanceCategoryID;
                          }
                          return (vars);
                        })
                      };
                      saveFeedbackComment(variables);
                    }
                  } else {
                    setModalState({
                      closeButton: true,
                      icon: 'icon-ml-warning cancel-icon-color',
                      title: 'Para poder Dar Feedback debes llenar todos los campos requeridos.',
                      visible: true
                    });
                  }
                });
              }}
            >
         Enviar
            </Button>
          </div>
        };
      }

      break;
    }
    default:
      return {
        status: data.status === 'Rejected' ? 'Rechazado' : 'Vencido',
        statusClasses: 'icon-me-error rejected-icon-color'
      };
    }

    return {};
  })();

  /// ESTOS SON LOS PROPS QUE LE LLEGAN AL CARD DEL DETALLE DE FEEFBACK
  const cardData = {
    title,
    subject,
    isGroup,
    subTitle,
    date,
    status,
    statusClasses,
    comments,
    setComments,
    CONDITION_BUTTON_SEND_FEEDBACK,
    answer,
    feedback,
    from,
    feedbackToMe,
    getFieldDecorator,
    listDefaultFeedbackAnswers,
    dontRespondFeedback,
    setDontRespondFeedback,
    notFeedbackReason,
    setNotFeedbackReason
  };
  useEffect(() => {
    setComments(data.comments);
  }, [data._id, data.rut]);

  useEffect(() => {
    if (listSkillsBehaviors?.length > 0) {
      setComments(addBehaviors({ comments: data.comments, listSkillsBehaviors }));
    }
  }, [listSkillsBehaviors, data]);

  if (loadingListSkillBehaviors) {
    return (<Loading/>);
  }

  return data._id ? (
    <div className='mievolucion-feedback-details'>
      <div className='mievolucion-feedback-details-card'>
        { isFeedbackNetwork
          ? <FeedbackNetworkCard {...{
            data: cardData, feedbackToMe, categories, getFieldDecorator
          }} />
          : <Fragment>
            <ItemDetailsCard {...{
              ...cardData
            }} />

          </Fragment>
        }
        <div className='d-flex flex-column'>
          {info}
        </div>

      </div>
    </div>
  ) : (
    <Fragment/>
  );
};

SingleItemDetails.propTypes = {
  form: PropTypes.object,
  categories: PropTypes.array,
  data: PropTypes.object,
  type: PropTypes.string,
  setModalState: PropTypes.func,
  feedbackToMyTeam: PropTypes.bool
};

SingleItemDetails.defaultProps = {
  form: {},
  categories: [],
  data: {},
  type: '',
  setModalState: () => {},
  feedbackToMyTeam: false
};

const ItemDetailsSingleCard = Form.create({ name: 'feedback-network' })(SingleItemDetails);

export default ItemDetailsSingleCard;

import React, { useEffect } from 'react';
import { forOwn } from 'lodash';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import MyEvolutionLayout from '../../layouts/mievolucion';
import SectionList from '../../components/shared/sectionList';
import { logPageView, initGA } from '../../helpers/googleAnalytics';
import { getHomeList } from '../../helpers/myEvolution/homeList';
import { getEnvVar } from '../../helpers/commons';
import { GET_SETTING } from '../../graphql/settings/queries';
import { getSettingValue } from '../../helpers/settings';

const REACT_APP_GA_TRACKING_CODE = getEnvVar('REACT_APP_GA_TRACKING_CODE');

const HomeMyEvolution = () => {
  const { data } = useSelector(({ userReducer }) => userReducer || {});
  const {
    HOOPP,
    permissions
  } = data || {};
  const {
    loading: loadingSettingEDD,
    data: settingEDD
  } = useQuery(GET_SETTING, {
    context: { clientName: 'myEvolution' },
    variables: { key: 'ENABLE_EDD' },
    fetchPolicy: 'network-only'
  });
  const { getSetting: setting = {} } = settingEDD || {};

  const isEDDEnabled = getSettingValue(setting?.value);

  const renderSections = () => {
    const results = [];
    forOwn(getHomeList({
      permissions: {
        ...permissions,
        INTERESTS_AND_SKILLS: HOOPP === 'HO'
      },
      isEDDEnabled: loadingSettingEDD ? false : isEDDEnabled
    }), (value, key) => {
      results.push({
        title: key,
        srcList: value
      });
    });
    return results.map((item, i) => (
      <SectionList category='Mi Evolución' {...item} key={`mievolucion${i}`} isListHorizontal={false} />
    ));
  };

  useEffect(() => {
    initGA(REACT_APP_GA_TRACKING_CODE);
    logPageView(window.location.pathname);
  }, []);

  return (
    <div className='home-list-page-mobile-horizontal'>
      <MyEvolutionLayout>
        <div>
          {renderSections()}
        </div>
      </MyEvolutionLayout>
    </div>
  );
};

export default HomeMyEvolution;

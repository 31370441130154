import React, { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLazyQuery, useMutation } from '@apollo/client';

import InfoMessage from '../../shared/InfoMessage';
import Input from '../../shared/forms/Input';
import SearchUsers from '../../shared/forms/SearchUsers';
import MainBtn from '../../shared/buttons/MainBtn';
import ModalMessage from '../../shared/ModalMessage';
import Error from '../../shared/Error';
import Loader from '../../shared/MLLoading';
import { fullCapitalizeFormat } from '../../../helpers/strings';
import {
  GET_LIST_EVALUATION_CALIBRATIONS,
  GET_CALIBRATION_USER_INFO
} from '../../../graphql/mievolucion/calibracion/queries';
import {
  CREATE_CALIBRATION,
  UPDATE_CALIBRATION
} from '../../../graphql/mievolucion/calibracion/mutations';
import { initializeTable, shapeDataToSend } from '../../../helpers/myEvolution/calibracion';

import '../../../static/styles/mievolucion/calibracion/stepperOne.less';
import SelectionRadios from './StepperOne/SelectionRadios';
import CalibrationsUploader from './StepperOne/CalibrationsUploader';
import { SEARCH_USERS } from '../../../graphql/user/queries';

const dateFormat = 'ddd DD MMM YYYY';
const context = { clientName: 'myEvolution' };
const fetchPolicy = { fetchPolicy: 'network-only' };
let userSearchData = {};

const StepperOne = ({
  calibrationDispatch, calibrationReducer
}) => {
  const {
    usersSelected,
    editingUsersToAdd,
    editingUsersToDrop,
    calibration,
    finalScores,
    skillScores,
    skills,
    performanceCategoryList,
    finalSkillScores,
    objetiveScores,
    calibrationId,
    formFields,
    formError
  } = calibrationReducer;
  const {
    rut: createdBy
  } = useSelector((state) => state.userReducer.data);
  const [showResultsUsers, setShowResultsUsers] = useState(false);
  const [confirmModal, setConfirmModal] = useState({
    visible: false,
    showIcon: true,
    icon: 'icon-ml-warning pending-icon-color',
    title: '',
    message: '',
    closeButton: false
  });
  const [uploadOption, setUploadOption] = useState(0);

  const getModalTitle = (code, user) => {
    const userName = `${fullCapitalizeFormat(user.name)} ${fullCapitalizeFormat(user.fatherLastName || user.secondName)}`;
    switch (code) {
    case 'WITHOUT_EDD':
    case 'WITHOUT_EDP':
    case 'EDD_COMMUNICATED': return `${userName} se le comunicó su evaluación de desempeño`;
    case 'WITHOUT_EDD_AND_EDP': return `${userName} no ha sido evaluado(a)`;
    case 'USER_IN_GRID': return `${userName} se encuentra en un proceso de calibración`;
    case 'USER_WAS_CALIBRATED': return `¿Estás seguroque deseas calibrar nuevamente a ${userName}?`;
    case 'NOT_EXIST_IN_COMPANY': return `${userName} no se encuentra en la compañía`;
    default: return '';
    }
  };

  const [getUserInfo, {
    loading: userInfoLoading
  }] = useLazyQuery(GET_CALIBRATION_USER_INFO, {
    context,
    ...fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getUserToCalibrate: userToCalibrate }) => {
      const { code, message } = userToCalibrate;
      const { userAttempt, users } = userSearchData;
      const userCantBeAdded = /WITHOUT_EDD_AND_EDP|EDD_COMMUNICATED|WITHOUT_EDD|WITHOUT_EDP|NOT_EXIST_IN_COMPANY|USER_IN_GRID/;
      const userNeedsConfirmation = /USER_WAS_CALIBRATED/;
      if (userCantBeAdded.test(code)) {
        setConfirmModal({
          ...confirmModal,
          visible: true,
          title: getModalTitle(code, userAttempt),
          message
        });
      } else if (userNeedsConfirmation.test(code)) {
        setConfirmModal({
          ...confirmModal,
          visible: true,
          title: getModalTitle(code, userAttempt),
          message,
          newUsers: users
        });
      } else {
        calibrationDispatch({
          type: 'set-usersSelected',
          payload: users
        });
      }
    }
  });

  const [getListEvaluationCalibrations, {
    data: calibrationData,
    loading: getCalibrationLoading,
    error: getCalibrationError
  }] = useLazyQuery(GET_LIST_EVALUATION_CALIBRATIONS, {
    context,
    ...fetchPolicy
  });

  const setUsersSelected = async (users, userAddedOrRemoved, action) => {
    const activateCall = action === 'add';
    if (activateCall) {
      userSearchData = { users, userAttempt: userAddedOrRemoved };
      await getUserInfo({ variables: { rut: userAddedOrRemoved.rut } });
    } else {
      calibrationDispatch({
        type: 'set-usersSelected',
        payload: users
      });
    }
  };

  const shouldCreate = usersSelected.size < 1 || !calibrationReducer.calibration;
  const shouldUpdate = usersSelected.size > 0 && calibrationReducer.calibration;

  const propsSearchUsers = {
    ...(uploadOption === 0 ? null : { className: 'hidden' }),
    label: 'Calibrar a',
    query: SEARCH_USERS,
    onCollection: 'evolution_calibration_availables',
    usersSelected,
    setUsersSelected,
    showResultsUsers,
    setShowResultsUsers,
    userAttribute: 'position'
  };

  const uploadUsersProps = {
    ...(uploadOption === 1 ? null : { className: 'hidden' }),
    shouldCreate,
    onSuccess: (data) => {
      const mapData = new Map(usersSelected);
      data.map((item) => mapData.set(item.data, item.user));

      if (shouldUpdate) {
        calibrationDispatch({
          type: 'set-updateByFile',
          payload: mapData
        });
      } else {
        calibrationDispatch({
          type: 'set-usersSelected',
          payload: mapData
        });
      }

      setUploadOption(0);
    }
  };

  const [createCalibration, {
    loading: createCalibrationLoading
  }] = useMutation(CREATE_CALIBRATION, {
    context,
    ...fetchPolicy,
    onCompleted: ({ createCalibration: resp }) => {
      calibrationDispatch({
        type: 'reset'
      });
      calibrationDispatch({
        type: 'set-calibration',
        payload: {
          calibration: resp
        }
      });
      calibrationDispatch({ type: 'step-forward' });
    },
    onError: (err) => {
      calibrationDispatch({
        type: 'set-modalState',
        payload: {
          visible: true,
          type: 'error',
          message: err.message
        }
      });
    }
  });

  const [updateEvaluationCalibration, {
    loading: updateCalibrationLoading
  }] = useMutation(UPDATE_CALIBRATION, {
    context,
    ...fetchPolicy,
    onCompleted: ({ updateCalibration: resp }) => {
      calibrationDispatch({
        type: 'reset'
      });
      calibrationDispatch({
        type: 'set-calibration',
        payload: {
          calibration: resp
        }
      });
      if (resp && resp.status === 'PENDING') {
        calibrationDispatch({ type: 'step-forward' });
      } else {
        calibrationDispatch({
          type: 'set-modalState',
          payload: {
            visible: true,
            type: 'success',
            title: 'La calibración se ha guardado con éxito',
            message: ''
          }
        });
      }
    },
    onError: (err) => {
      calibrationDispatch({
        type: 'set-modalState',
        payload: {
          visible: true,
          type: 'error',
          message: err.message
        }
      });
    }
  });

  useEffect(() => {
    if (!calibrationData && calibrationId) {
      getListEvaluationCalibrations({ variables: { createdBy, _id: calibrationId } });
    }
    if (calibrationData && calibrationData.listCalibration) {
      const calibrationToEdit = calibrationData.listCalibration;
      calibrationDispatch({ type: 'set-calibration', payload: { calibration: calibrationToEdit } });
    }
  }, [calibrationData]);

  const createCalibrationSelect = async () => {
    if (usersSelected.size > 0) {
      const toSend = {
        calibrationMeeting: moment(formFields.calibrationMeeting),
        groupName: formFields.groupName,
        createdBy,
        members: Array.from(usersSelected, ([, value]) => (value)).map((item) => item.rut)
      };
      await createCalibration({
        variables: toSend
      });
    }
  };

  const updateCalibration = async (status) => {
    if (usersSelected.size > 0) {
      const toSend = shapeDataToSend({
        formFields,
        calibration,
        status,
        finalScores,
        skillScores,
        skills,
        performanceCategoryList,
        finalSkillScores,
        objetiveScores,
        ...(editingUsersToAdd.length && { add: editingUsersToAdd }),
        ...(editingUsersToDrop.length && { drop: editingUsersToDrop })
      });
      await updateEvaluationCalibration({
        variables: toSend
      });
    }
  };

  useEffect(() => {
    if (calibrationReducer.calibration) {
      calibrationDispatch({
        type: 'set-calibrationTable',
        payload: initializeTable(calibrationReducer)
      });
    }
  }, [calibrationReducer.calibration]);

  const onFieldChange = (e) => {
    const isDate = e instanceof moment || e == null;
    const field = isDate ? 'calibrationMeeting' : 'groupName';
    const value = isDate ? e?.format() : e.target.value;
    if (!isDate && value.length > 50) {
      return;
    }
    calibrationDispatch({
      type: 'set-form-fields',
      payload: { field, value }
    });
  };

  const chooseModalBtns = (isAlready) => {
    switch (true) {
    case isAlready instanceof Map:
      return [
        {
          text: 'Si, calibrar nuevamente',
          type: 'default',
          onClickButton: () => {
            calibrationDispatch({
              type: 'set-usersSelected',
              payload: confirmModal.newUsers
            });
            setConfirmModal({ ...confirmModal, visible: false, newUsers: null });
          }
        },
        {
          text: 'No, mantener calibración',
          type: 'primary',
          onClickButton: () => {
            setConfirmModal({ ...confirmModal, visible: false, newUsers: null });
          }
        }
      ];
    default:
      return [
        {
          text: 'Entiendo',
          type: 'primary',
          onClickButton: () => {
            setConfirmModal({ ...confirmModal, visible: false, newUsers: null });
          }
        }
      ];
    }
  };

  const checkFormAndSubmit = (action, status) => {
    const fields = Object.keys(formFields);
    const errors = { ...formError };
    let errorFlag = false;
    fields.forEach((item) => {
      if (!formFields[item]) {
        errorFlag = true;
        errors[item] = true;
      } else {
        errors[item] = false;
      }
    });
    if (errorFlag) {
      calibrationDispatch({
        type: 'set-form-errors',
        payload: errors
      });
    } else {
      action(status);
    }
  };

  const getRenders = () => {
    if (getCalibrationLoading
      || createCalibrationLoading
      || updateCalibrationLoading
      || userInfoLoading
    ) return <Loader />;

    if (getCalibrationError) return <Error />;

    return (
      <>
        { confirmModal?.visible
        && <ModalMessage
          {...confirmModal}
          buttons={chooseModalBtns(confirmModal.newUsers)}
        />
        }
        <p className='text-label mt-5'>Nombre del grupo a calibrar</p>
        <div className={`field-wrapper ${formError.groupName && 'field-error'}`}>
          <Input
            autoComplete="false"
            onChange={onFieldChange}
            name='groupName'
            value={formFields.groupName}
            placeholder="Ingresar nombre del grupo*"
            className="w-50"
          />
          <p className='text-right w-50 mt-1 small'>{formFields.groupName.length}/50</p>
        </div>
        <p className='text-label mt-4'>Fecha reunión calibración</p>
        <div className={`field-wrapper ${formError.calibrationMeeting && 'field-error'}`}>
          <DatePicker
            value={formFields.calibrationMeeting
              ? moment(formFields.calibrationMeeting) : null
            }
            format={dateFormat}
            onChange={onFieldChange}
            className='w-50'
          />
          {formError.calibrationMeeting && <p className='small mt-1'>Debes seleccionar una fecha</p>}
        </div>
        <InfoMessage
          color="suave"
          classes="mt-3 mb-2 mt-5"
          message={
            <div>
              <span>Selecciona a los colaboradores que aplican para esta calibración. Puedes</span>
              {' '}<b>buscarlos uno a uno</b> <span>o también puedes</span> <b>subir un archivo excel</b>
              {' '}<span>con todas las personas que deseas calibrar.</span>
            </div>
          }
        />

        <SelectionRadios setValue={setUploadOption} value={uploadOption}/>

        <CalibrationsUploader {...uploadUsersProps}/>

        <SearchUsers {...propsSearchUsers}/>

        <div className="d-flex justify-content-center mt-5 bottom-btns-wrapper">
          <MainBtn
            onClick={() => checkFormAndSubmit(shouldUpdate ? updateCalibration : createCalibrationSelect, 'PENDING')}
            text="Guardar Grilla"
            mlType="inverse-main"
            width="200px"
          />
          <MainBtn
            onClick={() => checkFormAndSubmit(updateCalibration, 'PENDING')}
            text="Calibración de Desempeño"
            disabled={shouldCreate}
            width="200px"
          />
        </div>
      </>
    );
  };

  return (
    <div className="stepper-one-component">
      {getRenders()}
    </div>
  );
};

StepperOne.propTypes = {
  calibrationDispatch: PropTypes.func,
  calibrationReducer: PropTypes.object
};

StepperOne.defaultProps = {
  calibrationDispatch: () => {},
  calibrationReducer: {}
};

export default StepperOne;
